import { ComponentProps, Fragment } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Grid } from '@material-ui/core';

import { Text } from '@hawkins/components';
import { useFormikContext } from 'formik';

import { FormikDropdown } from 'components/forms/fields/dropdowns/FormikDropdown';
import {
  FormikInputWithGrid as Input,
  FormikStrippedInput,
} from 'components/forms/fields/input';
import {
  FormikRepeaterWithButtons,
  enumToDropdownOptions,
  getIdentifierType,
  getMopType,
} from 'utils';
import {
  blankMOPPayload,
  MOPTypes,
  TranslationNamespace,
  OtherForm as OtherFormType,
  DeviceForm as DeviceFormType,
} from 'types';

function MopPayloadFields({ count, disabled }: any) {
  const { t } = useTranslation(TranslationNamespace.LEP);

  const { values, setFieldValue } = useFormikContext<
    DeviceFormType | OtherFormType
  >();

  const cardOptions = enumToDropdownOptions(MOPTypes, t as any);

  // the API only accepts a `mop` at this point, so here is where
  // we mash the routing and account numbers together to form
  // an API-friendly format
  function concatRoutingAndAccount() {
    setFieldValue(
      `memberIdPayload.identifierPayloads[${count}].mop`,
      `${values.memberIdPayload.identifierPayloads[count].routingNumber}::${values.memberIdPayload.identifierPayloads[count].accountNumber}`
    );
  }

  // the API only accepts a `mop` at this point, so here is where
  // we mash the Brazil Bank Account info together to form
  // an API-friendly format
  function concatBRBankAccount() {
    setFieldValue(
      `memberIdPayload.identifierPayloads[${count}].mop`,
      `${values.memberIdPayload.identifierPayloads[count].branchNumber}::${values.memberIdPayload.identifierPayloads[count].accountNumber}::${values.memberIdPayload.identifierPayloads[count].digit}`
    );
  }

  function getMOPFieldsForType() {
    switch (values.memberIdPayload.identifierPayloads[count].mopType) {
      case 'US_BA':
        return (
          <>
            <Input
              required
              component={FormikStrippedInput}
              disabled={disabled}
              label={t('field_routing_number')}
              name={`memberIdPayload.identifierPayloads[${count}].routingNumber`}
              onBlur={concatRoutingAndAccount}
              placeholder={t('field_routing_number')}
              sm={6}
            />
            <Input
              required
              component={FormikStrippedInput}
              disabled={disabled}
              label={t('field_account_number')}
              name={`memberIdPayload.identifierPayloads[${count}].accountNumber`}
              onBlur={concatRoutingAndAccount}
              placeholder={t('field_account_number')}
              sm={6}
            />
          </>
        );
      case 'BR_BA':
        return (
          <>
            <Input
              required
              component={FormikStrippedInput}
              disabled={disabled}
              label={t('mop_type8_field1')}
              name={`memberIdPayload.identifierPayloads[${count}].branchNumber`}
              onBlur={concatBRBankAccount}
              placeholder={t('mop_type8_field1')}
              sm={6}
            />
            <Input
              required
              component={FormikStrippedInput}
              disabled={disabled}
              label={t('mop_type7_field2')}
              name={`memberIdPayload.identifierPayloads[${count}].accountNumber`}
              onBlur={concatBRBankAccount}
              placeholder={t('mop_type7_field2')}
              sm={6}
            />
            <Input
              required
              component={FormikStrippedInput}
              disabled={disabled}
              label={t('mop_type8_field2')}
              name={`memberIdPayload.identifierPayloads[${count}].digit`}
              onBlur={concatBRBankAccount}
              placeholder={t('mop_type8_field2')}
              sm={6}
            />
          </>
        );
      case 'CC':
      case 'EU_DIRECT_DEBIT':
      case 'GIFT_CARD':
        return (
          <Input
            required
            component={FormikStrippedInput}
            disabled={disabled}
            label={t('mop_identifier')}
            name={`memberIdPayload.identifierPayloads[${count}].mop`}
            placeholder={t('mop_identifier')}
            sm={12}
          />
        );
      default:
        return (
          <Input
            required
            disabled={disabled}
            label={t('mop_identifier')}
            name={`memberIdPayload.identifierPayloads[${count}].mop`}
            placeholder={t('mop_identifier')}
            sm={12}
          />
        );
    }
  }

  return (
    <Fragment key={`${count}MemberIdentificationInfo`}>
      <Input
        required
        component={FormikDropdown}
        disabled={disabled}
        label={t('mop_payment_type')}
        md={5}
        name={`memberIdPayload.identifierPayloads[${count}].identifier`}
        onChange={(selected: any) => {
          // set type & mopType values based on selected mop type (api requires some types not be labeled "MOP" if not true MOP)
          setFieldValue(
            `memberIdPayload.identifierPayloads[${count}].type`,
            getIdentifierType(selected.value)
          );
          setFieldValue(
            `memberIdPayload.identifierPayloads[${count}].mopType`,
            getMopType(selected.value)
          );
          // Clear all field values when the mopType changes,
          // so we are not sending mixed data.
          setFieldValue(
            `memberIdPayload.identifierPayloads[${count}].routingNumber`,
            ''
          );
          setFieldValue(
            `memberIdPayload.identifierPayloads[${count}].accountNumber`,
            ''
          );
          setFieldValue(
            `memberIdPayload.identifierPayloads[${count}].branchNumber`,
            ''
          );
          setFieldValue(
            `memberIdPayload.identifierPayloads[${count}].digit`,
            ''
          );
          setFieldValue(`memberIdPayload.identifierPayloads[${count}].mop`, '');
        }}
        options={cardOptions}
        placeholder={t('mop_payment_type')}
        sm={6}
      />
      <Grid item md={7} sm={6} xs={12}>
        <Grid container spacing={2}>
          {getMOPFieldsForType()}
        </Grid>
      </Grid>
    </Fragment>
  );
}

export function MOPRepeater({ disabled = false }: { disabled: boolean }) {
  const { t } = useTranslation(TranslationNamespace.LEP);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Text bold level={1}>
          {t('mop_identifier')}
        </Text>
        <Text colorVariant="faded">{t('MOP_EXPLANATION')}</Text>
      </Grid>
      <FormikRepeaterWithButtons
        addText={t('button_add')}
        allowZero={true}
        componentGenerator={(index) => (
          <MopPayloadFields count={index} disabled={disabled} />
        )}
        disabled={disabled}
        itemStructure={blankMOPPayload}
        name="memberIdPayload.identifierPayloads"
        removeText={t('button_remove')}
      />
    </Grid>
  );
}
