import IconArrowRight from '@hawkins/icons/lib/iconArrowRight';
import IconArrowLeft from '@hawkins/icons/lib/iconArrowLeft';
import IconChevronRight from '@hawkins/icons/lib/iconChevronRight';
import IconChevronLeft from '@hawkins/icons/lib/iconChevronLeft';

import { useIsRTL } from './useIsRTL';

export function useLocaleIcons() {
  const { isRTLLanguage } = useIsRTL();

  return isRTLLanguage
    ? {
        IconArrowLeft: IconArrowRight,
        IconArrowRight: IconArrowLeft,
        IconChevronLeft: IconChevronRight,
        IconChevronRight: IconChevronLeft,
      }
    : {
        IconArrowLeft: IconArrowLeft,
        IconArrowRight: IconArrowRight,
        IconChevronLeft: IconChevronLeft,
        IconChevronRight: IconChevronRight,
      };
}
