import { Text } from '@hawkins/components';
import { colors } from '@hawkins/variables';

export function RequiredStar() {
  return (
    <Text color={colors.blackT60} component="span">
      *
    </Text>
  );
}
