import { createStyles, makeStyles } from '@hawkins/styles';
import { colors } from '@hawkins/variables';

export const accordion = makeStyles(() =>
  createStyles({
    accordionBasic: {
      '&.Mui-expanded': {
        borderBottom: 'none !important',
        backgroundColor: colors.white,
      },
    },
    accordionTransparent: {
      backgroundColor: 'transparent',
    },
  })
);
