import { Grid } from '@material-ui/core';

import { IconAdd } from '@hawkins/icons';
import { Button } from '@hawkins/components';

import { formFields } from 'styles';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import { useAnalytics } from 'hooks/useAnalytics';

export interface FormikRepeaterButtonsProps {
  disabled?: boolean;
  name: string;
  itemStructure: any;
  addText: string;
}

/**
 * A component that handles an arbitrary number of fields to be stored in a
 * a formik array value.
 * @param name The name of the formik key that has an array value
 * @param itemStructure The default value that will be pushed when a new
 * repeater value is added
 */
export function FormikRepeaterButtons({
  disabled = false,
  name,
  itemStructure,
  addText,
}: FormikRepeaterButtonsProps) {
  const { values, setFieldValue }: { values: any; setFieldValue: any } =
    useFormikContext();
  const { logRepeaterButtonAdd } = useAnalytics();

  const classes = formFields();
  if (disabled) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Button
        className={classes.row}
        icon={IconAdd}
        onClick={() => {
          // add a new blank repeater item
          const temp = get(values, name);
          temp.push(itemStructure);
          setFieldValue(name, temp);
          logRepeaterButtonAdd(name);
        }}
        variant="secondary"
      >
        {addText}
      </Button>
    </Grid>
  );
}
