import {
  DeviceForm,
  EmergencyForm,
  FraudForm,
  IdentifierPayload,
  OtherForm,
} from '../types';

const getCryptexPublicKey = async () => {
  const res = await fetch(`/api/tickets/cryptexkey`, {
    method: 'GET',
  });
  return await res.text();
};

const stringToArrayBuffer = (str: string) => {
  const buffer = new ArrayBuffer(str.length);
  const view = new Uint8Array(buffer);
  for (let i = 0, strLen = str.length; i < strLen; i++)
    view[i] = str.charCodeAt(i);
  return buffer;
};

const base64ToArrayBuffer = (base64String: string) => {
  const binaryString = atob(base64String);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};

const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
};

export const generateCryptexEncryptedMop = async (mopValue: string) => {
  const cryptexPublicKey = await getCryptexPublicKey();
  const algorithm = { name: 'RSA-OAEP', hash: 'SHA-1' };
  const key = await crypto.subtle.importKey(
    'spki',
    base64ToArrayBuffer(cryptexPublicKey),
    algorithm,
    true,
    ['encrypt']
  );
  const result = await crypto.subtle.encrypt(
    algorithm,
    key,
    stringToArrayBuffer(mopValue)
  );
  return arrayBufferToBase64(result);
};

const doEncryption = async (payload: IdentifierPayload) => {
  const encrypted = await generateCryptexEncryptedMop(payload.mop);
  if (encrypted !== null) {
    payload.mop = encrypted;
  }
};

const isFraudForm = (x: any): x is FraudForm =>
  (x as FraudForm).identifierPayloads !== undefined;
export const encryptMOPData = async (
  form: FraudForm | EmergencyForm | DeviceForm | OtherForm
) => {
  if (isFraudForm(form)) {
    for (const payload of form.identifierPayloads) {
      await doEncryption(payload);
    }
  } else {
    for (const payload of form.memberIdPayload.identifierPayloads) {
      await doEncryption(payload);
    }
  }
};
