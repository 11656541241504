import { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';

import { Button, Text } from '@hawkins/components';
import IconAdd from '@hawkins/icons/lib/iconAdd';
import IconMinus from '@hawkins/icons/lib/iconMinus';

import { FormikInputWithGrid as Input } from 'components/forms/fields';
import { formFields } from 'styles';
import { TranslationNamespace } from 'types';
import { useAnalytics } from 'hooks/useAnalytics';

export function OtherToggle({ disabled = false }: { disabled?: boolean }) {
  const { t } = useTranslation(TranslationNamespace.LEP);
  const [show, setShow] = useState<boolean>(false);
  const classes = formFields();
  const { setFieldValue } = useFormikContext();
  const { logRepeaterButtonAdd, logRepeaterButtonRemove } = useAnalytics();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Text bold level={1}>
          {t('inv_type_other')}
        </Text>
        <Text colorVariant="faded">{t('netflix_identifier_description')}</Text>
      </Grid>
      {show && (
        <Input
          multiline
          disabled={disabled}
          name="memberIdPayload.other"
          rows={5}
          sm={12}
        />
      )}
      {!disabled && (
        <Grid item xs={12}>
          {show ? (
            <Button
              className={classes.row}
              icon={IconMinus}
              onClick={() => {
                setShow(false);
                setFieldValue('memberIdPayload.otherIncluded', false);
                setFieldValue('memberIdPayload.other', '');
                logRepeaterButtonRemove('memberIdPayload.otherIncluded');
              }}
              variant="secondary"
            >
              {t('button_remove')}
            </Button>
          ) : (
            <Button
              className={classes.row}
              icon={IconAdd}
              onClick={() => {
                setShow(true);
                setFieldValue('memberIdPayload.otherIncluded', true);
                logRepeaterButtonAdd('memberIdPayload.otherIncluded');
              }}
              variant="secondary"
            >
              {t('button_add')}
            </Button>
          )}
        </Grid>
      )}
    </Grid>
  );
}
