import { DropdownOption, InputProps } from '@hawkins/components';
import { Grid, GridSize } from '@material-ui/core';

import { FormikInput } from '@hawkins-community/formik-hawkins';

import { formFields, inputField } from 'styles';

interface FormikInputWithGridProps extends InputProps {
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
  required?: boolean;
  component?: any;
  options?: DropdownOption[];
  maxSelectableDate?: string | undefined;
  countryCode?: string;
  fullWidth?: boolean;
}

/**
 * An input field with a built in grid wrapper. This is only used to make the JSX
 * for a form page easier to read and is totally optional.
 */
export function FormikInputWithGrid({
  xs = 12,
  sm = 6,
  md = undefined,
  lg = undefined,
  required = false,
  component = FormikInput,
  fullWidth = true,
  ...args
}: FormikInputWithGridProps) {
  const classes = formFields();
  const input = inputField();
  const Component = component;

  return (
    <Grid item className={classes.row} lg={lg} md={md} sm={sm} xs={xs}>
      <Component
        className={input.root}
        fullWidth={fullWidth}
        requirementType={required ? 'required-star' : undefined}
        {...args}
      />
    </Grid>
  );
}
