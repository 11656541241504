import * as usa from './usa';
import * as brazil from './brazil';

export interface State {
  name: string;
  isoCode: string;
}

export const STATES_BY_COUNTRY: Record<string, State[]> = {
  US: usa.states,
  BR: brazil.states,
};

export { getStatesForCountryCode } from './getStatesForCountryCode';
