import useTranslation from 'next-translate/useTranslation';
import { Text } from '@hawkins/components';

import { RequiredStar } from 'components/text/RequiredStar';
import { TranslationNamespace } from 'types';

export function RequiredFieldMessage() {
  const { t } = useTranslation(TranslationNamespace.LEP);
  return (
    <Text>
      <RequiredStar /> {t('required_field_asterix')}
    </Text>
  );
}
