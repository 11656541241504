import { Children, TicketTypeString } from 'types';

import { Button } from '@hawkins/components';
import { useAnalytics } from 'hooks/useAnalytics';

interface EditButtonProps extends Children {
  onClick?: () => void;
  ticketType: TicketTypeString;
}

export function EditButton({
  children,
  onClick = () => null,
  ticketType,
}: EditButtonProps) {
  const { logEditButtonClick } = useAnalytics();
  return (
    <>
      <Button
        onClick={() => {
          logEditButtonClick(ticketType);
          onClick();
        }}
      >
        {children}
      </Button>
    </>
  );
}
