import { Translate } from 'next-translate';

export function enumToDropdownOptions(
  input: Record<string, unknown>,
  t?: Translate
) {
  const cardOptions = [];
  for (const [key, value] of Object.entries(input)) {
    // If a Translate function is passed, use it, otherwise
    // just use the value as the dropdown label.
    const label = t ? t(value as string) : (value as string);
    cardOptions.push({ label: label, value: key as string });
  }
  return cardOptions;
}
