import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import clone from 'lodash/clone';

import { IconMinus } from '@hawkins/icons';
import { Box, Button } from '@hawkins/components';
import { spacings } from '@hawkins/variables';

import {
  FormikRepeaterButtons,
  FormikRepeaterButtonsProps,
} from 'components/forms';
import { formFields } from 'styles';
import { useAnalytics } from 'hooks/useAnalytics';

/**
 * Component that will call the componentGenerator to create some JSX elements.
 * Those elements will be repeated as many times as there are array elements
 * in the formik fields.
 */
interface FormikRepeaterProps {
  name: string;
  componentGenerator: (index: number) => JSX.Element;
  removeText: string;
  allowZero?: boolean;
  disabled?: boolean;
  max?: number;
}

export function FormikRepeater({
  name,
  componentGenerator,
  removeText,
  allowZero = false,
  disabled = false,
}: FormikRepeaterProps) {
  const { values, setFieldValue }: { values: any; setFieldValue: any } =
    useFormikContext<FormikRepeaterProps>();
  const { logRepeaterButtonRemove } = useAnalytics();
  const minItems = allowZero ? 0 : 1;
  const classes = formFields();
  const items = [];
  for (let index = 0; index < get(values, name).length; index++) {
    items.push(
      <Box
        key={`${name}${index}`}
        columnGap={spacings.space1}
        display="flex"
        justifyContent="space-between"
      >
        <Box width="100%">
          <Grid container spacing={2}>
            {componentGenerator(index)}
          </Grid>
        </Box>
        {get(values, name).length > minItems && (
          <Box
            className={classes.row}
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
          >
            <Button
              disabled={disabled}
              icon={IconMinus}
              onClick={() => {
                // remove the repeater item
                const temp = clone(get(values, name));
                temp.splice(index, 1);
                setFieldValue(name, temp);
                logRepeaterButtonRemove(name);
              }}
              variant="secondary"
            >
              {removeText}
            </Button>
          </Box>
        )}
      </Box>
    );
  }
  return <>{items}</>;
}

interface FormikRepeaterWithButtonsProps
  extends FormikRepeaterButtonsProps,
    FormikRepeaterProps {}

export function FormikRepeaterWithButtons({
  name,
  disabled,
  itemStructure,
  addText,
  removeText,
  componentGenerator,
  allowZero,
  max,
}: FormikRepeaterWithButtonsProps) {
  const { values }: { values: any } = useFormikContext();
  const showButtons = max ? get(values, name).length < max : true;
  return (
    <Grid item xs={12}>
      <FormikRepeater
        allowZero={allowZero}
        componentGenerator={componentGenerator}
        disabled={disabled}
        name={name}
        removeText={removeText}
      />
      {showButtons && (
        <FormikRepeaterButtons
          addText={addText}
          disabled={disabled}
          itemStructure={itemStructure}
          name={name}
        />
      )}
    </Grid>
  );
}
