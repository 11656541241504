import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';

import { Box, Heading, Text } from '@hawkins/components';
import { spacings } from '@hawkins/variables';
import { IconWarning } from '@hawkins/icons';

import { TranslationNamespace } from 'types';
import { NavigationButton } from 'components/navigation';

export function EmergencyRequestCTA() {
  const { t } = useTranslation(TranslationNamespace.LEP);
  return (
    <>
      <Box alignItems="center" columnGap={spacings.space1} display="flex">
        <IconWarning />
        <Heading level={1}>{t('request_emergency_title')}</Heading>
      </Box>

      <Text gutterBottom={spacings.space3} gutterTop={spacings.space3}>
        <Trans
          components={[<Text key="1" bold color="red" component="span" />]}
          i18nKey="lepui:request_emergency_instructions"
        />
      </Text>

      <Box display="flex" justifyContent="flex-end">
        <NavigationButton href="/emergency/" variant="primary-destructive">
          {t('button2')}
        </NavigationButton>
      </Box>
    </>
  );
}
