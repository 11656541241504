import { useFormikContext } from 'formik';
import { FocusError } from 'focus-formik-error';

import { Children, TicketTypeString } from 'types';

import { Button } from '@hawkins/components';
import { useAnalytics } from 'hooks/useAnalytics';

interface ValidateButtonProps extends Children {
  onChange?: () => void;
  disabled?: boolean;
  ticketType: TicketTypeString;
}

export function ValidateButton({
  children,
  onChange = () => null,
  disabled = false,
  ticketType,
}: ValidateButtonProps) {
  const {
    isValidating,
    isSubmitting,
    errors,
    touched,
    validateForm,
    setTouched,
  } = useFormikContext();
  const { logValidateButtonClick, logValidationErrors } = useAnalytics();
  return (
    <>
      <FocusError formik={{ isValidating, touched, errors, isSubmitting }} />
      <Button
        disabled={isValidating || disabled}
        onClick={() => {
          logValidateButtonClick(ticketType);
          validateForm().then((errors) => {
            // ignore the error type, we just need error to
            // touch all fields so error messages will
            // actually show up after validation.
            setTouched(errors as any, true);
            if (Object.keys(errors).length) {
              logValidationErrors(errors, ticketType);
            }
            // If we have no errors, we want to enter "Review" (disabled)
            // mode in our form, so we'll scroll up and fire the onChange().
            if (Object.keys(errors).length === 0) {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              onChange();
            }
          });
        }}
        type="submit"
      >
        {children}
      </Button>
    </>
  );
}
