import useTranslation from 'next-translate/useTranslation';
import { Heading } from '@hawkins/components';
import { spacings } from '@hawkins/variables';

import { BackButton } from 'components/navigation';
import { TranslationNamespace } from 'types';

interface FormHeaderProps {
  header: string;
  hideBackButton?: boolean;
}

export function PageHeader({
  header,
  hideBackButton = false,
}: FormHeaderProps) {
  const { t } = useTranslation(TranslationNamespace.LEP);
  return (
    <>
      {!hideBackButton && <BackButton />}
      <Heading gutterTop={spacings.space4} level={1}>
        {t('law_enforcement_request_portal')}
      </Heading>
      <Heading
        gutterBottom={spacings.space5}
        gutterTop={spacings.space2}
        level={5}
      >
        {header}
      </Heading>
    </>
  );
}
