import React, { useMemo } from 'react';
import PhoneNumber from 'awesome-phonenumber';
import { useField, useFormikContext } from 'formik';

import { Input, InputProps } from '@hawkins/components';
import { makeStyles } from '@hawkins/styles';
import { useIsRTL } from 'hooks';

export interface FormikPhoneNumberProps extends InputProps {
  name: string;
  countryCode?: string | undefined;
}

export function FormikPhoneNumber({
  name,
  countryCode = undefined, // eslint-disable-line @typescript-eslint/no-unused-vars
  ...args
}: FormikPhoneNumberProps): JSX.Element {
  const { setFieldValue }: { setFieldValue: any } = useFormikContext();
  const [field, meta] = useField(name);
  const { isRTLLanguage } = useIsRTL();

  const useInputStyles = makeStyles({
    rtlIgnore: {
      unicodeBidi: 'plaintext !important' as any,
      textAlign: isRTLLanguage ? 'right' : 'left',
      '&::placeholder': {
        unicodeBidi: 'plaintext !important' as any,
        textAlign: isRTLLanguage ? 'right' : 'left',
      },
    },
  });

  const inputClasses = useInputStyles();

  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: input } = event.target;
    const formattedInput = input
      .replace(/\s+/g, '')
      .replace(/^(\+?)(.*)/, '+$2');
    const phoneNumber = new PhoneNumber(formattedInput);

    setFieldValue(name, phoneNumber.getNumber() || formattedInput);
  };

  const displayValue = useMemo(
    () =>
      new PhoneNumber(field.value).getNumber('international') || field.value,
    [field.value]
  );

  return (
    <Input
      classes={{ input: inputClasses.rtlIgnore }}
      onChange={onValueChange}
      validationMessage={meta.touched && !!meta.error ? meta.error : undefined}
      validationStatus={meta.touched && !!meta.error ? 'error' : undefined}
      value={displayValue}
      {...args}
      placeholder="+X XXX-XXX-XXXX"
    />
  );
}
