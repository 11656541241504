import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import { TextLink, TextLinkProps } from '@hawkins/components';
import { colors } from '@hawkins/variables';

import { TranslationNamespace } from 'types';
import { useAnalytics, useLocaleIcons } from 'hooks';

export interface BackButtonProps extends TextLinkProps {
  text?: string;
}

export function BackButton({ text }: BackButtonProps) {
  const router = useRouter();
  const { t } = useTranslation(TranslationNamespace.LEP);
  const { logBackButtonClick } = useAnalytics();
  const { IconArrowLeft } = useLocaleIcons();

  if (!text) {
    text = t('back');
  }

  return (
    <TextLink
      onClick={() => {
        logBackButtonClick();
        router.back();
      }}
      style={{ color: colors.black }}
      variant="standalone"
    >
      <IconArrowLeft />
      {text}
    </TextLink>
  );
}
