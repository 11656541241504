import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { rtlLocales } from 'utils/constants';

export function useIsRTL() {
  const { locale } = useRouter();
  const [rtl, setRtl] = useState(false);

  useEffect(() => {
    locale && rtlLocales.has(locale) ? setRtl(true) : setRtl(false);
  }, [locale]);

  return {
    isRTLLanguage: rtl,
  };
}
