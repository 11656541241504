import { AxiosError } from 'axios';

export function handleApiError(error: AxiosError) {
  if (error.response) {
    console.error('API Error', error.response.data);
  } else if (error.request) {
    console.error('API Error', error.request);
  } else {
    console.error('API Error', error.message);
  }
}
