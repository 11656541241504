import { useState } from 'react';

import { Input, InputProps } from '@hawkins/components';
import { useField, useFormikContext } from 'formik';

export interface FormikStrippedInputProps extends InputProps {
  name: string;
}

export function FormikStrippedInput({
  name,
  ...args
}: FormikStrippedInputProps) {
  const { setFieldValue }: { setFieldValue: any } = useFormikContext();
  const [field, meta] = useField(name);
  const [value, setValue] = useState(field.value);

  const onChange = (evt: any) => {
    const val = evt.target.value;
    const strippedVal = val.replace(/[^0-9a-z]/gi, '');
    setValue(strippedVal);
    setFieldValue(name, strippedVal);
    if (args.onChange) {
      args.onChange(evt);
    }
  };

  return (
    <Input
      {...field}
      onChange={onChange}
      validationMessage={meta.touched && !!meta.error ? meta.error : undefined}
      validationStatus={meta.touched && !!meta.error ? 'error' : undefined}
      value={value}
      {...args}
    />
  );
}
