import { isBrowser, isLocalhost, isDeployTestEnv } from './runtime';

export * from './enumToDropdown';
export * from './getEmailDomain';
export * from './handleApiError';
export * from './Repeater';
export * from './getDateString';
export * from './encryptMOP';
export * from './userSessionData';
export * from './states';
export * from './identifierUtils';
export * from './runtime';

export const gotoPortal = () => {
  if (isBrowser()) {
    isLocalhost() || isDeployTestEnv()
      ? (window.location.href = `${process.env.NEXT_PUBLIC_TEST_PORTAL_URL}`)
      : (window.location.href = `${process.env.NEXT_PUBLIC_PORTAL_URL}`);
  }
};
