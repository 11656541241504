import { STATES_BY_COUNTRY, State } from './states';

const byName = (a: State, b: State) => a.name.localeCompare(b.name);

/**
 * Returns an array of states for a given country code, sorted by state name.
 *
 * @param {string} countryCode - The ISO code of the country.
 * @returns {State[]} An array of states for the given country code, or an empty array if no states are found.
 */
export const getStatesForCountryCode = (countryCode: string): State[] =>
  STATES_BY_COUNTRY[countryCode]?.sort(byName) || [];
