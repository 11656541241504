import Link from 'next/link';

import { Button } from '@hawkins/components';

import { Children, Icon, PrimaryButtonVariant } from 'types';
import { useAnalytics } from 'hooks/useAnalytics';

export interface NavigationButtonProps extends Children {
  href: string;
  icon?: Icon;
  variant?: PrimaryButtonVariant;
}

export function NavigationButton({
  children,
  href,
  icon,
  variant,
}: NavigationButtonProps) {
  const { logNavigationButtonClick } = useAnalytics();
  return (
    <Link href={href}>
      <a style={{ textDecoration: 'none' }}>
        <Button
          icon={icon}
          onClick={() => logNavigationButtonClick(href)}
          variant={variant}
        >
          {children}
        </Button>
      </a>
    </Link>
  );
}
