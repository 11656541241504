import { useState, useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Grid } from '@material-ui/core';

import { Paper, Text } from '@hawkins/components';
import { spacings } from '@hawkins/variables';
import { FormikCheckbox } from '@hawkins-community/formik-hawkins';
import { useFormikContext } from 'formik';

import {
  FormikInputWithGrid as Input,
  FormikDatePickerInputLocalized,
} from 'components/forms/fields/input';
import { FormSectionProps } from '.';
import { FormSectionHeader, RequiredFieldMessage } from 'components/text';
import { TranslationNamespace, UserInfoForm } from 'types';
import { checkbox } from 'styles';

interface SignatureProps extends FormSectionProps {
  userInfo: UserInfoForm;
}

export function Signature({
  disabled = false,
  header,
  description = '',
  userInfo,
}: SignatureProps) {
  const { t } = useTranslation(TranslationNamespace.LEP);
  const { setFieldValue } = useFormikContext();
  const DEFAULT_SIGNATURE_PLACEHOLDER = t('signature_field_placeholder');
  const [signaturePlaceholder, setSignaturePlaceholder] = useState<string>(
    DEFAULT_SIGNATURE_PLACEHOLDER
  );
  const classes = checkbox();

  // Formik/Yup need to know the value of the Name that
  // we will be using in order to validate the signature field,
  // so we manually add it here to be used later in the
  // schema for the signature.
  useEffect(() => {
    const fullName = `${userInfo?.givenName} ${userInfo?.familyName}`;
    setSignaturePlaceholder(fullName);
    setFieldValue('signatureToMatch', fullName);
  }, []);

  return (
    <>
      <FormSectionHeader description={description} header={header} />
      <Paper>
        <FormikCheckbox
          required
          classes={{ ...classes }}
          disabled={disabled}
          label={t('signature_label')}
          name="signatureAgreement"
        />
        <Text
          component="ul"
          gutterBottom={spacings.space5}
          gutterTop={spacings.space3}
        >
          <Text component="li" gutterBottom={spacings.space1}>
            {t('signature_desc')}
          </Text>
          <Text component="li">{t('signature_desc1')}</Text>
        </Text>
        <Grid container spacing={2}>
          <Input
            required
            disabled={disabled}
            label={t('signature_field_label')}
            name="signature"
            placeholder={signaturePlaceholder}
            sm={8}
          />
          <Input
            disabled
            required
            component={FormikDatePickerInputLocalized}
            label={t('signature_date_label')}
            name="consentDate"
            sm={4}
          />
        </Grid>
        <Grid container item sm={6} xs={12}>
          <RequiredFieldMessage />
        </Grid>
      </Paper>
    </>
  );
}
