import { spacings } from '@hawkins/variables';
import { Divider } from '@hawkins/components';

export function SectionDivider() {
  return (
    <Divider
      style={{
        marginBottom: spacings.space4,
        marginTop: spacings.space4,
      }}
    />
  );
}
