import React, {
  useCallback,
  FocusEvent,
  ReactElement,
  useState,
  useEffect,
} from 'react';
import { useField } from 'formik';
import {
  Dropdown,
  DropdownOption,
  DropdownSingleProps,
} from '@hawkins/components';

export interface FormikDropdownProps extends DropdownSingleProps<any> {
  /**
   * A name attribute corresponding to the Formik value name
   */
  name: string;
}

/**
 * A formik single-selection dropdown component that only returns the value of
 * the selected field. Inspired by the Studio UI FormikDropdown component.
 */
export const FormikDropdown = (props: FormikDropdownProps): ReactElement => {
  const { name, onBlur, onChange, ...rest } = props;
  const [field, meta, helpers] = useField(name);

  // get the current value of the formik state field value
  const formikStateFieldValue = useCallback(() => {
    if (rest.options) {
      return rest.options.find((o) => o.value === field.value);
    }
    return null;
  }, [rest.options, field.value]);

  // default to the formik state value. We need a local state version to hold
  // the dropdown value/label pair, since the value alone will not work.
  const [value, setValue] = useState<DropdownOption>(formikStateFieldValue());

  // if the formik state value changes, update the dropdown
  useEffect(() => {
    setValue(formikStateFieldValue());
  }, [field.value, formikStateFieldValue]);

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>): void => {
      helpers.setTouched(true);

      if (onBlur) {
        onBlur(event);
      }
    },
    [helpers, onBlur]
  );

  const handleChange = useCallback(
    (newValue: DropdownOption | null | undefined): void => {
      if (newValue) {
        helpers.setValue(newValue?.value);
        setValue(newValue);

        if (onChange) {
          onChange(newValue);
        }
      }
    },
    [helpers, onChange]
  );

  return (
    <Dropdown
      {...field}
      onBlur={handleBlur}
      onChange={handleChange}
      validationMessage={meta.touched && !!meta.error ? meta.error : undefined}
      validationStatus={meta.touched && !!meta.error ? 'error' : undefined}
      value={value}
      {...rest}
    />
  );
};
