import { FormikErrors, FormikTouched, useFormikContext } from 'formik';
import { FormikInput } from '@hawkins-community/formik-hawkins';

import { OtherForm } from 'types';

export function AtLeastOneError() {
  const {
    errors,
    touched,
  }: { errors: FormikErrors<OtherForm>; touched: FormikTouched<OtherForm> } =
    useFormikContext();
  if (
    errors.memberIdPayload?.atLeastOne &&
    touched.memberIdPayload?.atLeastOne
  ) {
    return (
      // We are using an actual FormikInput here as a workaround in
      // order to connect with focus-formik-error, which sets
      // focus on a formik field that is in an error state.
      // This error message is very custom and does not apply to one
      // particular field, so we kind of fake it here and hide
      // the actual input itself.
      <FormikInput
        name="memberIdPayload.atLeastOne"
        onFocus={(evt) => evt.currentTarget.blur()}
        style={{ height: 0, minHeight: 0, overflow: 'hidden', border: 'none' }}
      />
    );
  }
  return null;
}
