import useTranslation from 'next-translate/useTranslation';

import { Grid } from '@material-ui/core';

import { Text } from '@hawkins/components';

import { FormikInputWithGrid as Input } from 'components/forms/fields/input';
import { FormikRepeaterWithButtons } from 'utils';
import { TranslationNamespace } from 'types';

function EmailFields({ count, disabled = false }: any) {
  const { t } = useTranslation(TranslationNamespace.LEP);
  return (
    <Input
      key={`emailAddresses${count}`}
      required
      disabled={disabled}
      label={t('field_email_address')}
      md={8}
      name={`memberIdPayload.emailAddresses[${count}]`}
      placeholder={t('field_email_address')}
      sm={12}
    />
  );
}

export function EmailRepeater({ disabled = false }: { disabled: boolean }) {
  const { t } = useTranslation(TranslationNamespace.LEP);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Text bold level={1}>
          {t('field_email_address')}
        </Text>
        <Text colorVariant="faded">{t('email_explanation')}</Text>
      </Grid>
      <FormikRepeaterWithButtons
        addText={t('button_add')}
        allowZero={true}
        componentGenerator={(index) => (
          <EmailFields count={index} disabled={disabled} />
        )}
        disabled={disabled}
        itemStructure=""
        name="memberIdPayload.emailAddresses"
        removeText={t('button_remove')}
      />
    </Grid>
  );
}
