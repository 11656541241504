import { Box, Heading } from '@hawkins/components';
import { spacings } from '@hawkins/variables';
import useTranslation from 'next-translate/useTranslation';

import { TranslationNamespace } from 'types';

export function PageTitle() {
  const { t } = useTranslation(TranslationNamespace.LEP);

  return (
    <Box
      columnGap={spacings.space3}
      display="flex"
      flexDirection="row"
      marginBottom={spacings.space5}
      maxWidth="460px"
    >
      <img height="86" src="/images/netflix-arch-logo.svg" />
      <Heading level={4}>{t('law_enforcement_request_portal')}</Heading>
    </Box>
  );
}
