import { makeStyles } from '@hawkins/styles';

export const checkbox = makeStyles({
  root: {
    alignItems: 'flex-start !important',
    columnGap: '8px',
  },
  label: {
    marginLeft: '0px',
  },
});
