import { Box, Heading, Text } from '@hawkins/components';
import { spacings } from '@hawkins/variables';

interface FormSectionHeaderProps {
  header: string;
  description?: string;
}

export function FormSectionHeader({
  header,
  description,
}: FormSectionHeaderProps) {
  return (
    <Box marginTop={spacings.space3} paddingBottom={spacings.space3}>
      <Heading gutterBottom={spacings.space3} level={1}>
        {header}
      </Heading>
      {description ? (
        <Text gutterBottom={spacings.space4}>{description}</Text>
      ) : null}
    </Box>
  );
}
