import { UserInfoForm } from 'types';

const SESSION_STORAGE_KEY = 'le_user_info';

// Save the user info in sessionStorage
export function saveSessionUserData(values: UserInfoForm) {
  sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(values));
}

export function getSessionUserData() {
  const unparedUserInfo = sessionStorage.getItem(SESSION_STORAGE_KEY);
  if (!unparedUserInfo) {
    return null;
  }
  return JSON.parse(unparedUserInfo);
}
