import { IdentifierPayload, IdentifierType, MOPType } from 'types';

type IdentifierConfigType = {
  [key in MOPType]: {
    searchCount: number;
    type: IdentifierType;
    mopType: MOPType | null;
  };
};

const identifierDefaults = {
  searchCount: 1,
  type: IdentifierType.MOP,
};

const identifierConfig: IdentifierConfigType = {
  [MOPType.PAYPAL]: {
    ...identifierDefaults,
    searchCount: 3,
    mopType: MOPType.PAYPAL,
  },
  [MOPType.BR_BA]: {
    ...identifierDefaults,
    mopType: MOPType.BR_BA,
  },
  [MOPType.CC]: {
    ...identifierDefaults,
    mopType: MOPType.CC,
  },
  [MOPType.EU_DIRECT_DEBIT]: {
    ...identifierDefaults,
    mopType: MOPType.EU_DIRECT_DEBIT,
  },
  [MOPType.US_BA]: {
    ...identifierDefaults,
    mopType: MOPType.US_BA,
  },
  [MOPType.GIFT_CARD]: {
    ...identifierDefaults,
    type: IdentifierType.GIFT_CARD,
    mopType: null,
  },
};

export function getIdentifierType(type: MOPType): IdentifierType | MOPType {
  return identifierConfig[type].type;
}

export function getMopType(type: MOPType): MOPType | null {
  return identifierConfig[type].mopType;
}

export function getCountForIdentifier(type: MOPType): number {
  return identifierConfig[type].searchCount;
}

export function getTotalIdentifierCount(
  identifierPayload: IdentifierPayload[]
) {
  return identifierPayload.reduce((acc: number, curr: IdentifierPayload) => {
    if (curr.identifier) {
      return acc + getCountForIdentifier(curr.identifier);
    } else {
      return 0;
    }
  }, 0);
}
