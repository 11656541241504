import { makeStyles } from '@hawkins/styles';

// AppLogo overrides. Replace directional margin with flex gap
export const appLogo = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '10px',
  },
  iconContainer: {
    marginRight: '0px',
  },
});

// DatePicker overrides applied when RTL language selected.
export const datePicker = makeStyles({
  calendarHeader: {
    '& button': {
      transform: 'rotate(180deg)',
    },
  },
  calendarTitleContainer: {
    '& p': {
      marginRight: '0px',
    },
    columnGap: '4px',
  },
});

// Validation error icon fix to support RTL languages
export const inputField = makeStyles({
  root: {
    paddingRight: '0px',
    '& svg': {
      width: '38px',
    },
  },
});
