import { Button } from '@hawkins/components';
import { useFormikContext } from 'formik';
import { Children } from 'types';

export interface FormikSubmitButtonProps extends Children {
  disabled?: boolean;
  onClick?: () => void;
}

export function FormikSubmitButton({
  children,
  disabled = false,
  onClick = () => null,
}: FormikSubmitButtonProps) {
  const { isSubmitting, isValidating } = useFormikContext();
  return (
    <Button
      disabled={disabled || isValidating || isSubmitting}
      executing={isSubmitting}
      onClick={() => onClick()}
      style={{
        height: 40,
        marginTop: 'auto',
        marginBottom: 'auto',
      }}
      type="submit"
    >
      {children}
    </Button>
  );
}
