/**
 * Determines if the current runtime is the browser. If false, we assume we're running in Node.
 */
export const isBrowser = (): boolean => typeof window !== 'undefined';

/**
 * Is the current runtime running locally via localhost?
 */
export const isLocalhost = (): boolean =>
  isBrowser() && window.location.hostname.includes('localhost');

/**
 * Is the current runtime deployed via Netflix infra?
 */
export const isDeployed = (): boolean => !isLocalhost();

/**
 * Is the current runtime deployed to the test environment?
 */
export const isDeployTestEnv = (): boolean =>
  isBrowser() && window.location.hostname.includes('test');

/**
 * Is the current runtime deployed to the production environment?
 */
export const isDeployProdEnv = (): boolean =>
  isDeployed() && !isDeployTestEnv();
