// This is a wrapper for the Hawkins FormikDatePickerInput component,
// so we can localize the month and day names in one location.
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

import {
  FormikDatePickerInput,
  FormikDatePickerInputProps,
} from '@hawkins-community/formik-hawkins';
import type { AllowedDatepickerFormats } from '@hawkins/components';
import { Text, Box } from '@hawkins/components';
import { colors, spacings } from '@hawkins/variables';
import { makeStyles, createStyles } from '@hawkins/styles';

import { TranslationNamespace } from 'types';
import { useFormikContext } from 'formik';

const useAdornmentStyles = makeStyles(() =>
  createStyles({
    requiredStar: {
      '&::after': {
        color: colors.red3,
        content: '"*"',
        display: 'inline-block',
        paddingLeft: spacings.space0,
        fontWeight: 'normal',
      },
    },
  })
);

const getLocaleDateFormat = (locale: string) => {
  const defaultFormat = 'MM/DD/YYYY';
  const localeDateFormats = {
    'MM/DD/YYYY': ['en'],
    'DD/MM/YYYY': [
      'en-GB',
      'es',
      'es-ES',
      'fr',
      'it',
      'nb',
      'pt',
      'pt-PT',
      'el',
    ],
    'YYYY/MM/DD': ['ar-EG'],
    'YYYY-MM-DD': ['fr-CA', 'sv'],
    'DD-MM-YYYY': ['nl', 'da'],
    'DD.MM.YYYY': ['he', 'pl', 'ro', 'fi', 'tr', 'uk', 'de'],
    'YYYY. MM. DD.': ['hu'],
    'DD. MM. YYYY': ['cs'],
    'DD. MM. YYYY.': ['hr'],
  };
  const formatLocaleTuple = Object.entries(localeDateFormats).find(
    ([, localeArr]) => {
      return localeArr.find((loc: string) => loc === locale);
    }
  );
  return formatLocaleTuple ? formatLocaleTuple[0] : defaultFormat;
};

// Manually adding props/markup that were deprecated in Hawkins 4.0 DatePickerInput. Just a temporary workaround
// as Hawkins to update the API in future release (currently 4.0.4)
interface FormikDatePickerLocalizedProps extends FormikDatePickerInputProps {
  /** Label placed above the input */
  label?: string;
  /**
   * String to be used as the input description.
   */
  description?: string;
  /**
   * If true, the DatePicker will not be editable
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * If set, adds a required adornment to the label.
   * Must provide a label in order to see this adornment
   *
   * - required-star: a "*" adornment is added
   */
  requirementType?: 'required-star' | undefined;
}

export function FormikDatePickerInputLocalized({
  name,
  label,
  description,
  disabled = false,
  requirementType,
  ...args
}: FormikDatePickerLocalizedProps) {
  const { t } = useTranslation(TranslationNamespace.LEP);
  const router = useRouter();
  const locale = router.locale;
  const adornmentClasses = useAdornmentStyles();
  const dateFormat = getLocaleDateFormat(locale as string);
  const { setFieldValue } = useFormikContext();

  const getTranslationForMonthName = ({ date }: { date: string }) => {
    const month = new Date(date)
      .toLocaleString('en', { month: 'long' })
      .toLowerCase();
    return t(`month_${month}`);
  };

  return (
    <>
      {(label || description) && (
        <Box paddingBottom={spacings.space1}>
          <Text
            bold
            className={requirementType ? adornmentClasses.requiredStar : ''}
          >
            {label}
          </Text>
          <Text color={colors.blackT60} level={1} variant="label">
            {description}
          </Text>
        </Box>
      )}
      <FormikDatePickerInput
        {...args}
        format={dateFormat as AllowedDatepickerFormats}
        inputProps={{
          placeholder: t('field_date_placeholder'),
          readOnly: true, // prevents manual input
          disabled,
        }}
        mask={false}
        name={name}
        onSelectedChange={(date) => {
          if (date && !Array.isArray(date)) {
            // as of Hawkins 4.0, DatePicker values are in ISO string format. For our purposes we need js dates.
            setFieldValue(name, new Date(date));
          }
        }}
        /// @ts-expect-error - We're getting errors even with the correct key names.
        translate={{
          'datepicker.dayName': ({ date }) => {
            const weekday = new Date(date)
              .toLocaleString('en', { weekday: 'long' })
              .toLowerCase();
            return t(`day_${weekday}`);
          },
          'datepicker.currentDisplayMonth': getTranslationForMonthName,
          'datepicker.currentDisplayYear': ({ date }) =>
            new Date(date).toLocaleString(locale, { year: 'numeric' }),
          'datepicker.dayNumber': ({ date }) =>
            +new Date(date).toLocaleString(locale, { day: 'numeric' }),
          'datepicker.monthName': getTranslationForMonthName,
          'datepicker.yearName': ({ date }) =>
            new Date(date).toLocaleString(locale, { year: 'numeric' }),
        }}
      />
    </>
  );
}
